<template>
  <Table  :row-class-name="rowClassName" :columns="columns1" :data="data1"></Table>
</template>

<script>
export default {
  name: "BITable",
  data(){
    return {
      columns1: [
        {
          title: '机构名称',
          key: 'name',
          align: 'center',
        },
        {
          title: '结业人数',
          key: 'xys',
          align: 'center',
        },
        {
          title: '就业人数',
          key: 'kc',
          align: 'center',
        },
        {
          title: '待业人数',
          key: 'qzsj',
          align: 'center',
        },
        {
          title: '就业率',
          key: 'dkjs',
          align: 'center',
        },
      ],

      data1: [
        {
          name: '机构(1)',
          xys: 18,
          kc: '15',
          qzsj: '55',
          dkjs:'15%'
        },
        {
          name: '机构(2)',
          xys: 18,
          kc: '15',
          qzsj: '55',
          dkjs:'15%'
        }


      ]
    }
  },
  methods:{
    rowClassName (row, index) {
      if (index >= 0) {
        return 'demo-table-info-row';
      }
      return '';
    }
  }
}
</script>

<style>
.ivu-table{
  background: none;
}
.ivu-table:before{
  background: none;
}
.ivu-table th{
  background-color: rgba(255,255,255,0);
  color: white;
  border: none;
}

.ivu-table .demo-table-info-row td{
  background: none;
  color: white;
  border:none;
}




</style>