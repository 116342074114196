<template>
  <div class="echart" id="echart-rankingBar" :style="{ float: 'left', width: '100%', height: '100%' }"></div>
</template>

<script>
  import echarts from 'echarts';
  import circlepurple from "../../static/imgs/circlepurple.png"
  import circleBlue from "../../static/imgs/circleblue.png"
  import circleGreen from "../../static/imgs/circlegreen.png"

  export default {
    name: 'rankingBar',
    data() {
      return {
        circlepurple: circlepurple,
        circleBlue: circleBlue,
        circleGreen: circleGreen,
      }
    },
    methods: {
      initChart() {
        let getchart = echarts.init(document.getElementById('echart-rankingBar'));
        var rankingBarOption = {

          grid: [
            {
              //左侧的柱状图grid
              width: "30%",
              left: '16%',
              top: '4%',
              right: '0',
              bottom: '0'
            },
            {
              //右侧的柱状图grid
              width: "36%",
              left: '61%',
              top: '4%',
              right: '0',
              bottom: '0'
            }
          ],
          xAxis: [
            {
              //左侧柱状图的X轴
              gridIndex: 0,//x 轴所在的 grid 的索引
              show: false
            },
            {
              //右侧柱状图的X轴
              gridIndex: 1,//x 轴所在的 grid 的索引
              show: false
            }],
          yAxis: [
            {
              //左侧柱状图的Y轴
              gridIndex: 0,//y轴所在的 grid 的索引
              splitLine: 'none',
              axisTick: 'none',
              axisLine: 'none',
              axisLabel: {
                align: 'left',
                verticalAlign: 'middle',
                padding: [0, 0, 0, -100],
                formatter: (p, index) => {
                  if (4 - index > 3) {
                    return '{index2|' + (4 - index) + '} ' + '{p|' + p + '}'
                  }else{
                    return '{index|' + (4 - index) + '} ' + '{p|' + p + '}'
                  }

                },
                rich: {
                  index: {
                    color: '#fff',
                    fontSize: '13',
                    borderRadius: 15,
                    borderWidth: '1px',
                    backgroundColor: {image: circlepurple},
                    width: 30,
                    height: 30,
                    align: 'center',
                  },
                  index2: {
                    color: '#fff',
                    fontSize: '13',
                    borderRadius: 15,
                    borderWidth: '1px',
                    backgroundColor: {image: circleGreen},
                    width: 30,
                    height: 30,
                    align: 'center',
                  },
                  p: {
                    padding: [0, 0, 0, 10],
                    color: '#fff',
                    fontSize: '13',
                  }
                }
              },
              data: ['机构1', '机构2', '机构3', '机构4'],
            },
            {
              //左侧柱状图的Y轴
              gridIndex: 0,//y轴所在的 grid 的索引
              splitLine: 'none',
              axisTick: 'none',
              axisLine: 'none',
              data: [1, 2, 3, 4],
              axisLabel: {
                show: false,
              }
            },
            {
              //左侧柱状图的Y轴
              gridIndex: 0,//y轴所在的 grid 的索引
              splitLine: 'none',
              axisTick: 'none',
              axisLine: 'none',
              data: []
            },

            {
              //右侧柱状图的Y轴
              gridIndex: 1,//y轴所在的 grid 的索引
              splitLine: 'none',
              axisTick: 'none',
              axisLine: 'none',
              axisLabel: {
                align: 'left',
                verticalAlign: 'middle',
                padding: [0, 0, 0, -100],
                formatter: (p, index) => {
                    return '{index|' + (8 - index) + '} ' + '{p|' + p + '}'
                },
                rich: {
                  index: {
                    color: '#fff',
                    fontSize: '13',
                    borderRadius: 15,
                    borderWidth: '1px',
                    backgroundColor: {image: circleBlue},
                    width: 30,
                    height: 30,
                    align: 'center',
                  },
                  p: {
                    padding: [0, 0, 0, 10],
                    color: '#fff',
                    fontSize: '13',
                  }
                }
              },
              data: ['机构5', '机构6', '机构7', '机构8'],
            },
            {
              //右侧柱状图的Y轴
              gridIndex: 1,//y轴所在的 grid 的索引
              splitLine: 'none',
              axisTick: 'none',
              axisLine: 'none',
              data: [5, 6, 7, 8],
              inverse: true,
              axisLabel: {
                show: false,
              },
            },
            {
              //右侧柱状图的Y轴
              gridIndex: 1,//y轴所在的 grid 的索引
              splitLine: 'none',
              axisTick: 'none',
              axisLine: 'none',
              data: []
            }
          ],
          series: [
            {
              name: '值',
              type: 'bar',
              xAxisIndex: 0,//使用的 x 轴的 index，在单个图表实例中存在多个 x 轴的时候有用。
              yAxisIndex: 0,//使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用。
              data: [555, 444, 333, 222],
              barWidth: 10,
              itemStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                    offset: 0,
                    color: "#49bdff" // 0% 处的颜色
                  }, {
                    offset: 1,
                    color: "#54ffd5" // 100% 处的颜色
                  }], false),
                  barBorderRadius: 6,
                }
              },
              z: 2
            },
            {
              name: '外框',
              type: 'bar',
              xAxisIndex: 0,//使用的 x 轴的 index，在单个图表实例中存在多个 x 轴的时候有用。
              yAxisIndex: 2,//使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用。
              barGap: '-100%',
              data: [1000, 1000, 1000, 1000],
              barWidth: 10,
              itemStyle: {
                normal: {
                  color: '#2d5271',
                  barBorderRadius: 6,
                }
              },
              z: 0
            },
            {
              name: '值',
              type: 'bar',
              xAxisIndex: 1,//使用的 x 轴的 index，在单个图表实例中存在多个 x 轴的时候有用。
              yAxisIndex: 3,//使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用。
              data: [777, 888, 999, 333],
              barWidth: 10,
              itemStyle: {
                normal: {
                  color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                    offset: 0,
                    color: "#49bdff" // 0% 处的颜色
                  }, {
                    offset: 1,
                    color: "#54ffd5" // 100% 处的颜色
                  }], false),
                  barBorderRadius: 6,
                }
              },
              z: 2
            },
            {
              name: '外框',
              type: 'bar',
              xAxisIndex: 1,//使用的 x 轴的 index，在单个图表实例中存在多个 x 轴的时候有用。
              yAxisIndex: 5,//使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用。
              barGap: '-100%',
              data: [1000, 1000, 1000, 1000],
              barWidth: 10,
              itemStyle: {
                normal: {
                  color: '#2d5271',
                  barBorderRadius: 6,
                }
              },
              z: 0
            }
          ]
        };

        getchart.setOption(rankingBarOption);
        //随着屏幕大小调节图表
        window.addEventListener('resize', () => {
          getchart.resize();
        });
      },
    },
    mounted() {
      this.initChart();
    },
  };
</script>

<style scoped></style>
