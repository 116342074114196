<template>
  <div class="head">
    <!--  标题  -->
    <div class="bt">
      <p class="bt1">就业情况统计分析</p>
    </div>
    <!--    头部-->
    <div class="top">
      <!--  结业学员  -->
      <div class="nums-wrap">
        <div style="font-size: 20px;color:#4CCCFF">结业学员</div>
        <div class="nums">
          <div class="div0"></div>
          <div class="div1"></div>
          <div class="div2"></div>
          <div class="div4"></div>
          <div class="div2"></div>
        </div>
        <div style="margin-left: 5px;font-size: 20px;color:#4CCCFF">
          人
        </div>
      </div>
      <!--  就业学员  -->
      <div class="nums-wrap">
        <div style="font-size: 20px;color:#4CCCFF">
          就业学员
        </div>
        <div class="nums">
          <div class="div0"></div>
          <div class="div0"></div>
          <div class="div3"></div>
          <div class="div4"></div>
          <div class="div2"></div>
        </div>
        <div style="margin-left: 5px;font-size: 20px;color:#4CCCFF">
          人
        </div>
      </div>
      <!--  待业学员  -->
      <div class="nums-wrap">
        <div style="font-size: 20px;color:#4CCCFF">
          待业学员
        </div>
        <div class="nums">
          <div class="div0"></div>
          <div class="div0"></div>
          <div class="div3"></div>
          <div class="div4"></div>
          <div class="div2"></div>
        </div>
        <div style="margin-left: 5px;font-size: 20px;color:#4CCCFF">
          人
        </div>
      </div>
      <!--  结业率  -->
      <div class="nums-wrap">
        <div style="font-size: 20px;color:#4CCCFF">
          就业率
        </div>
        <div class="nums">
          <div class="div8"></div>
          <div class="div6"></div>
          <div class="div-dot"></div>
          <div class="div5"></div>
          <div class="div5"></div>
        </div>
        <div style="margin-left: 5px;font-size: 20px;color:#4CCCFF">
          %
        </div>
      </div>
    </div>
    <!--    中间-->
    <div class="middle">
      <!--  左边    -->
      <div style="width: calc((100% - 20px) * 0.25) ">
        <div class="title">
          数据统计
        </div>
        <div style="display: flex;flex-direction: column;height: calc(100% - 40px);" class="m-left">
          <!--     待业人数     -->

          <div class="admissionsPeople1">
            <img :src="admissionsPeople" width="35" height="35" style="margin-right: 5px"/>
            <div style="flex: 1">
              <p style="font-size: 12px;color: white">待业人数</p>
              <div class="sjtjclass">
                <p class="NumClass">560</p>
                <p class="sjhbClass">环比去年
                  <Icon type="md-arrow-round-up" color="#00CC39"/>
                  <span style="color: #00CC39">10%</span>
                </p>
                <img :src="zhu" width="60" height="25"/>
              </div>
            </div>
          </div>
          <!--     结业人数     -->
          <div class="admissionsPeople1">
            <img :src="ClassNumber" width="35" height="35" style="margin-right: 5px"/>
            <div style="flex: 1">
              <p style="font-size: 12px;color: white">结业人数</p>
              <div class="sjtjclass">
                <p class="NumClass">3560</p>
                <p class="sjhbClass">环比去年
                  <Icon type="md-arrow-round-up" color="#00CC39"/>
                  <span style="color: #00CC39">10%</span>
                </p>
                <img :src="zhu" width="60" height="25"/>
              </div>
            </div>
          </div>
          <!--     就业人数     -->
          <div class="admissionsPeople1">
            <img :src="staying" width="35" height="35" style="margin-right: 5px"/>
            <div style="flex: 1">
              <p style="font-size: 12px;color: white">就业人数</p>
              <div class="sjtjclass">
                <p class="NumClass">3560</p>
                <p class="sjhbClass">环比去年
                  <Icon type="md-arrow-round-up" color="#00CC39"/>
                  <span style="color: #00CC39">10%</span>
                </p>
                <img :src="zhu" width="60" height="25"/>
              </div>
            </div>
          </div>
          <!--     就业率     -->
          <div class="admissionsPeople1">
            <img :src="staying" width="35" height="35" style="margin-right: 5px"/>
            <div style="flex: 1">
              <p style="font-size: 12px;color: white">就业率</p>
              <div class="sjtjclass">
                <p class="NumClass">83.43</p>
                <p class="sjhbClass">环比去年
                  <Icon type="md-arrow-round-up" color="#00CC39"/>
                  <span style="color: #00CC39">10%</span>
                </p>
                <img :src="zhu" width="60" height="25"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--中间-->
      <div style="width: calc((100% - 20px) * 0.5);margin-left: 10px ">
        <div class="title">
          机构分布、综合排名
        </div>
        <div class="org" style="height: calc(100% - 40px);display: flex;justify-content: center">
          <img :src="earth" width="auto" height="100%"/>
        </div>
      </div>
      <!--      右边-->
      <div style="width: calc((100% - 20px) * 0.25);margin-left: 10px;display: flex;flex-direction: column ">
        <!--        上-->
        <div style="height: calc((100% - 10px) * 0.5);position: relative">
          <div class="title">机构就业情况</div>
          <border style="width: 100%;height: calc(100% - 40px);"/>
          <div class="marquee-wrap">
            <div class="marquee-list" :class="{'animate-up': animateUp}">
              <div class="list-child"
                   v-for="(item, index) in yuanData" :key="index">
                <div>{{item.name}}</div>
                <div>{{item.time}}</div>
              </div>
            </div>
          </div>
        </div>
        <!--        下-->
        <div style="height: calc((100% - 10px) * 0.5);margin-top: 10px;position: relative">
          <div class="title">
            培训就业率统计
          </div>
          <border style="width: 100%;height:  calc(100% - 40px)"/>
          <div class="degreeDistribution1">
            <!--   本期就业率   -->
            <current-period-gauge style="height: 80%"/>
            <!--   本年就业率   -->
            <current-year-gauge style="height: 80%"/>

            <!--   去年就业率   -->
            <last-year-gauge style="height: 80%"/>
          </div>
        </div>
      </div>
    </div>
    <!--    底部-->
    <div class="bottom">
      <div style="width: calc((100% - 10px) * 0.50) ;position: relative">
        <div class="title">
          招生、报考、结业趋势
        </div>
        <border style="width: 100%;height: calc(100% - 40px);"/>
        <div class="echart-wrap">
          <period-pie/>
        </div>
      </div>
      <div style="width: calc((100% - 10px) * 0.50) ;position: relative;margin-left: 10px">
        <div class="title">
          就业率排名
        </div>
        <border style="width: 100%;height: calc(100% - 40px);"/>
        <div class="echart-wrap">
          <ranking-bar/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import bt from '@/static/img/标题.png'
  import content from '@/static/img/content.png'
  import admissionsPeople from '@/static/img/教师1.png'
  import declineBar from '@/static/img/declineBar.png'
  import riseBar from '@/static/img/riseBar.png'
  import ClassNumber from '@/static/img/logojieye.png'
  import staying from '@/static/img/logojiuye.png'
  import earth from '@/static/img/earth.png'
  import currentPeriodGauge from "@/view/employmentStatus/currentPeriodGauge";
  import currentYearGauge from "@/view/employmentStatus/currentYearGauge";
  import lastYearGauge from "@/view/employmentStatus/lastYearGauge";
  import periodPie from "@/view/employmentStatus/periodPie";
  import BITable from "@/view/employmentStatus/employmentBigTable";
  import rankingBar from "@/view/employmentStatus/rankingBar";
  import zhu from '@/static/img/柱1.png'
  import border from "../../components/border.vue"

  export default {
    name: "BI",
    components: {
      currentPeriodGauge, currentYearGauge, lastYearGauge,
      periodPie,
      rankingBar,
      BITable,
      border
    },
    data() {
      return {
        zhu: zhu,
        bt: bt, //标题图片
        content: content, //内容图片cjs
        admissionsPeople: admissionsPeople, //待业人数
        declineBar: declineBar, //下降柱状图
        riseBar: riseBar, //上升柱状图
        ClassNumber: ClassNumber, //结业人数
        staying: staying, //就业人数
        earth: earth, //地球图片
        height: window.innerHeight + 'px',
        yuanData: [
          {
            id: 1,
            name: '孙德胜学员签约xx机构，成功就业',
            time: '2021-06-01',
          },
          {
            id: 2,
            name: '张旭学员签约xx机构，成功就业',
            time: '2021-06-02',
          },
          {
            id: 3,
            name: '王丽丽员签约xx机构，成功就业',
            time: '2021-06-03',
          },
          {
            id: 4,
            name: '张大壮学员签约xx机构，成功就业',
            time: '2021-06-04',
          },
          {
            id: 5,
            name: '李丽学员签约xx机构，成功就业',
            time: '2021-06-05',
          },
          {
            id: 6,
            name: '王亮学员签约xx机构，成功就业',
            time: '2021-06-06',
          },
          {
            id: 7,
            name: '王淑华学员签约xx机构，成功就业',
            time: '2021-06-07',
          },
          {
            id: 8,
            name: '闫鹏涛学员签约xx机构，成功就业',
            time: '2021-06-08',
          }, {
            id: 9,
            name: '张小花学员签约xx机构，成功就业',
            time: '2021-06-09',
          }, {
            id: 10,
            name: '胡丽亚学员签约xx机构，成功就业',
            time: '2021-06-10',
          },

        ],
        animateUp: false,
        timer: null
      }
    },
    methods: {
      scrollAnimate() {
        this.animateUp = true
        setTimeout(() => {
          this.yuanData.push(this.yuanData[0])
          this.yuanData.shift()
          this.animateUp = false
        }, 500)
      }
    },
    mounted() {
      this.timer = setInterval(this.scrollAnimate, 1500);
    },
    destroyed() {
      clearInterval(this.timer)
    }

  }
</script>

<style scoped lang="scss">
  /*页面背景渐变色*/
  .head {
    background: radial-gradient(ellipse, #00102E 0, #000B27 100%);
    height: 100%;
    overflow-y: hidden;
  }

  /*标题*/
  .bt {
    background-image: url("../../static/imgs/标题.png");
    background-size: 100% 45px;
    background-repeat: no-repeat;
    background-position: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65px;
  }

  .bt1 {
    color: rgb(0, 255, 255);
    font-size: 27px;
  }

  .top {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    margin: auto 10px 10px auto;
    height: 35px;
  }

  .middle {
    display: flex;
    padding: 0 10px;
    height: calc((100% - 130px) * 0.62);
  }

  .bottom {
    display: flex;
    padding: 0 10px;
    margin-top: 10px;
    height: calc((100% - 130px) * 0.38);
  }

  .title {
    color: white;
    background-color: rgb(0, 26, 77);
    text-align: center;
    width: 100%;
    height: 30px;
    line-height: 30px;
    margin-bottom: 10px;
  }

  .echart-wrap {
    position: absolute;
    top: 60px;
    z-index: 999;
    width: 100%;
    height: calc(100% - 70px);
    display: flex;
    align-items: center;
    justify-content: center
  }

  /*教师数量*/
  .NumberOfTeachers {
    width: 100%;
    height: 100%;
    position: relative; /*相対定位*/
    /*display:flex;*/
  }

  .NumberOfTeachers1 {
    width: 50%;
    height: 60px;
    display: flex;
    align-items: center; /* 垂直居中 */
    margin-left: 38%;
    position: absolute; /*絶対定位*/
    top: 0;
    left: -4%;
  }

  .NumberOfTeachers2 {
    width: 40px;
    height: 36px;
    box-shadow: 0px 0px 10px #4185F0 inset;
    margin-left: 3%;
    font-size: 24px;
    text-align: center;
    color: rgb(0, 255, 255);

  }

  .Teachers {
    width: 40%;
    height: 60px;
    position: absolute; /*絶対定位*/
    line-height: 60px;
    font-size: 30px;
    text-align: center;
    color: rgb(0, 255, 255);
    top: 0;
    left: 0;
  }

  .Teachers1 {
    width: 12%;
    height: 60px;
    position: absolute; /*絶対定位*/
    line-height: 60px;
    font-size: 30px;
    text-align: center;
    color: rgb(0, 255, 255);
    top: 0;
    right: 3%;
  }

  /*学生数量*/
  .student {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center; /* 水平居中 */
  }

  .student1 {
    width: 40%;
    height: 60px;
    position: relative; /*相対定位*/

  }

  .Number {
    width: 40%;
    height: 60px;
    position: absolute; /*絶対定位*/
    line-height: 60px;
    font-size: 30px;
    text-align: center;
    color: rgb(0, 255, 255);
    top: 0;
    left: 0;
  }

  .Number1 {
    width: 55%;
    height: 60px;
    display: flex;
    align-items: center; /* 垂直居中 */
    margin-left: 38%;
    position: absolute; /*絶対定位*/
    top: 0;
    left: 0;
  }

  .Number2 {
    width: 40px;
    height: 36px;
    box-shadow: 0px 0px 10px #4185F0 inset;
    margin-left: 3%;
    font-size: 24px;
    text-align: center;
    color: rgb(0, 255, 255);

  }

  .Number3 {
    width: 12%;
    height: 60px;
    position: absolute; /*絶対定位*/
    line-height: 60px;
    font-size: 30px;
    text-align: center;
    color: rgb(0, 255, 255);
    top: 0;
    right: -6%;
  }

  /*课时数量*/
  .hourNumber {
    width: 100%;
    height: 100%;
    position: relative; /*相対定位*/
  }

  .hour {
    width: 40%;
    height: 60px;
    position: absolute; /*絶対定位*/
    line-height: 60px;
    font-size: 30px;
    text-align: center;
    color: rgb(0, 255, 255);
    top: 0;
    left: 0;
  }

  .hour1 {
    width: 50%;
    height: 60px;
    display: flex;
    align-items: center; /* 垂直居中 */
    margin-left: 33%;
    position: absolute; /*絶対定位*/
    top: 0;
    left: 0;
  }

  .hour2 {
    width: 40px;
    height: 36px;
    box-shadow: 0px 0px 10px #4185F0 inset;
    margin-left: 3%;
    font-size: 24px;
    text-align: center;
    color: rgb(0, 255, 255);

  }

  .hour3 {
    width: 20%;
    height: 60px;
    position: absolute; /*絶対定位*/
    line-height: 60px;
    font-size: 30px;
    text-align: center;
    color: rgb(0, 255, 255);
    top: 0;
    right: 7%;
  }

  .hour4 {
    height: 60%;
    margin-left: 3%;
    font-size: 30px;
    text-align: center;
    color: rgb(0, 255, 255);
  }

  .dataStatistics {
    width: 100%;
    height: 8%;
    background-color: rgb(0, 26, 77);
    text-align: center;
    line-height: 40px;
  }

  /*招生人次*/
  .admissionsPeople {
    width: 100%;
    height: 17%;
    background-color: rgb(8, 26, 62);
  }

  .m-left .admissionsPeople1:last-child {
    margin-bottom: 0;
  }

  .admissionsPeople1 {
    width: 100%;
    height: calc((100% - 30px) * 0.25);
    background-color: rgb(0, 20, 61);
    margin-bottom: 10px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;


    .sjtjclass {
      color: white;
      width: 100%;
      height: 90%;
      display: flex;
      align-items: flex-end;
      justify-content: space-around;


      .NumClass {
        color: #2db7f5;
        font-size: 18px;
      }

      .sjhbClass {
        font-size: 12px;
      }

    }

  }

  /*开班数量*/
  .ClassNumber {
    width: 100%;
    height: 17%;
    background-color: rgb(8, 26, 62);
  }

  /*报考人次*/
  .staying {
    width: 100%;
    height: 17%;
    background-color: rgb(8, 26, 62);
  }

  /*人均学时*/
  .ClassHoursPerCapita {
    width: 100%;
    height: 17%;
    background-color: rgb(8, 26, 62);
  }

  /*师资情况*/
  .TeachingSituation {
    width: 100%;
    height: 100%;
    background-color: rgb(0, 26, 77);
    text-align: center;
    line-height: 40px;
  }

  .TeachingSituation1 {
    width: 100%;
    height: 100%;
    position: relative; /*相対定位*/
  }

  .TeachingSituation2 {
    width: 92%;
    height: 88%;
    position: absolute; /*絶対定位*/
    bottom: 5%;
    left: 2%;
  }

  /*培训情况*/
  .trainingSituation {
    width: 100%;
    height: 100%;
    background-color: rgb(0, 26, 77);
    text-align: center;
    line-height: 40px;
  }

  .trainingSituation1 {
    width: 100%;
    height: 100%;
    text-align: center;
    overflow: hidden;
  }


  /*招生,报考,结业趋势*/
  .tendency {
    width: 100%;
    height: 100%;
    background-color: rgb(0, 26, 77);
    text-align: center;
    line-height: 40px;
  }

  .tendency1 {
    width: 100%;
    height: 100%;
    position: relative; /*相対定位*/
  }

  .tendency2 {
    width: 96%;
    height: 88%;
    position: absolute; /*絶対定位*/
    bottom: 5%;
    left: 2%;
  }

  /*合格率统计*/
  .percentPass {
    width: 100%;
    height: 100%;
    background-color: rgb(0, 26, 77);
    text-align: center;
    line-height: 40px;
  }

  .percentPass1 {
    width: 100%;
    height: 100%;
    position: relative; /*相対定位*/
  }

  /*本期就业率*/
  .currentPeriod {
    width: 31%;
    height: 90%;
    position: absolute; /*絶対定位*/
    bottom: 5%;
    left: 3%;
  }

  /*本年就业率*/
  .currentYear {
    width: 30%;
    height: 90%;
    position: absolute; /*絶対定位*/
    bottom: 5%;
    left: 36%;
  }

  /*去年就业率*/
  .lastYear {
    width: 31%;
    height: 90%;
    position: absolute; /*絶対定位*/
    bottom: 5%;
    left: 68%;
  }

  /*学时趋势*/
  .SchoolTrends {
    width: 100%;
    height: 100%;
    background-color: rgb(0, 26, 77);
    text-align: center;
    line-height: 40px;
  }

  .SchoolTrends1 {
    width: 100%;
    height: 100%;
    position: relative; /*相対定位*/

  }

  .SchoolTrends2 {
    width: 100%;
    height: 89%;
    position: absolute; /*絶対定位*/
    bottom: 5%;
    left: 2%;

  }

  /*培训情况雷达*/
  .trainingSituationRadar {
    width: 100%;
    height: 100%;
    background-color: rgb(0, 26, 77);
    text-align: center;
    line-height: 40px;
  }

  .trainingSituationRadar1 {
    width: 100%;
    height: 100%;
    position: relative; /*相対定位*/
  }

  .trainingSituationRadar2 {
    width: 94%;
    height: 88%;
    position: absolute; /*絶対定位*/
    bottom: 5%;
    left: 2%;
  }

  .marquee-wrap {
    width: 100%;
    height: calc(100% - 70px);
    top: 50px;
    z-index: 999;
    margin: 15px auto 0 auto;
    overflow: hidden;
    position: absolute;

    .marquee-list {
      .list-child {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 12px;
        font-size: 14px;
        color: white;
        position: relative;
        padding-left: 30px;
        padding-right: 15px;

        &::before {
          content: " ";
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          width: 12px;
          left: 15px;
          height: 12px;
          border-radius: 12px;
          background: #ffffff;
        }
      }

      li {
        width: 100%;
        height: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding: 0 20px;
        list-style: none;
        line-height: 40px;
        text-align: center;
        color: #fff;
        font-size: 18px;
        font-weight: 400;
      }

    }

    .animate-up {
      transition: all 0.5s ease-in-out;
      transform: translateY(-40px);
    }

  }

  .org {
    width: 100%;
    height: 100%;
    background-color: rgb(0, 20, 61);

  }

  .degreeDistribution1 {
    width: 100%;
    position: absolute; /*相対定位*/
    height: calc(100% - 40px);
    top: 50px;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    z-index: 999;
  }

  .nums-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc((100% - 60px) * 0.25);

    .nums {
      flex: auto;
      display: flex;

      div {
        flex: 1;
        width: calc((100% - 100px) * 0.2);
        height: 35px;
        margin-left: 5px;
        padding: 3px 5px;
        box-shadow: inset 0 0 10px 1px #2163CA;
        background-size: 50%;
        background-repeat: no-repeat;
        background-position: center;
      }

      .div0 {
        background-image: url("../../static/imgs/0.png");
      }

      .div1 {
        background-image: url("../../static/imgs/1.png");
      }

      .div2 {
        background-image: url("../../static/imgs/2.png");
      }

      .div3 {
        background-image: url("../../static/imgs/3.png");
      }

      .div4 {
        background-image: url("../../static/imgs/4.png");
      }

      .div5 {
        background-image: url("../../static/imgs/5.png");
      }

      .div6 {
        background-image: url("../../static/imgs/6.png");
      }

      .div7 {
        background-image: url("../../static/imgs/7.png");
      }

      .div8 {
        background-image: url("../../static/imgs/8.png");
      }

      .div9 {
        background-image: url("../../static/imgs/9.png");
      }

      .div-dot {
        box-shadow: none !important;
        position: relative;

        &::before {
          content: "  ";
          width:15px;
          height: 15px;
          left: 0;
          right: 0;
          margin: auto;
          position: absolute;
          bottom: 0;
          background-color: #4CCCFF;
          clip-path: polygon(73% 60%, 100% 81%, 73% 100%, 24% 100%, 2% 82%, 25% 61%);
        }
      }
    }
  }

</style>
